body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 75px;
  padding: 10px;
  justify-content: start;
  display: flex;
}

.App-header {
  background-color: #282c30;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

}

.App-header::-webkit-scrollbar {
  display: none;
}

.App-link {
  color: #61dafb;
}


.MenuWrapper{
  background-color: #282c30;

}

.App-footer {
  background-color: #282c30;
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
}

.FooterWrapper{
  display: flex;
  flex-direction: row;

}

.LoadingWrapper{
  height:90vh
}


.App {
    text-align: center;
  }
  
  .App-logo {
    height: 75px;
    padding: 10px;
    justify-content: center;
    display: flex;
  }

  .AppHeaderWrapper{
    width: 100%;
    background-color: #282c30;
    display: flex;
    justify-content: center;
  }
  
  .App-header {
    background-color: #282c30;
    height: 80px;
    width:850px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: white;  
  }


  .LogoWrapper{
    display: flex;
    justify-content: center;
  }

  .StickyStyle{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 500;
    width: 100%;
  }
  
  .App-header::-webkit-scrollbar {
    display: none;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  
  .MenuWrapper{
    background-color: #282c30;    
  }
  
  @media (min-width: 768px) { 
      
  .MenuTitle{
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  
  .MenuTitle img{
    height: 100%; 
    width: 730px;
    object-fit: contain;
  }

  .AppHeaderMiddle{
    display: flex;
    justify-content: center;
    width:30%;
    max-width:30%;
  }


  .AppHeaderLeft{
    display: flex;
    justify-content: start;
    width:35%;
    max-width:35%;
  }

  .AppHeaderRight{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #e7e7e7;
    align-items: flex-end;
    width:35%;
    max-width:35%;
  }

  
  .allergenicImageStyle{
    max-width: 800px;
  }


  }
  
  
  @media (max-width: 768px) { 
      
  .MenuTitle{
    width: 100%;
    overflow: hidden;
  }
  .MenuTitle img{
    height: 100%; 
    width: 100%; 
    object-fit: contain;
  }
  
  .AppHeaderLeft{
    display: flex;
    width:35%;
    max-width:35%;
  }

  
  .AppHeaderRight{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #e7e7e7;
    align-items: flex-end;
    width:35%;
    max-width:35%;
  }

  
  .allergenicImageStyle{
    max-width: 100%;
  }
  }
  
  .MenuTitleWrapper{
    display: block;
  }
  
  .App-footer {
    background-color: #282c30;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
  }
  
  .LoadingWrapper{
    height:90vh
  }

@media (min-width: 768px) {

  .menuCardEntry {
    width: 700px;
  }

  .menuCardPrice {
    display: flex;
    flex: 2 1;
    justify-content: flex-end;
    min-width: 60px;
    text-align: right;
    font-size: 18px;
    color: #282c30;
    font-weight: 600;
    font-family: "Poppins"
  }

  .menuCardPricePartySingle {
    display: flex;
    flex: 2 1;
    justify-content: flex-end;
    min-width: 60px;
    text-align: right;
    font-size: 15px;
    color: #282c30;
    font-weight: 500;
    font-family: "Poppins"
  }
}

@media(max-width:767px) {
  .menuCardEntry {
    width: -webkit-fill-available;
  }

  .menuCardPrice {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    min-width: 70px;
    text-align: right;
    font-size: 18px;
    color: #282c30;
    font-weight: 600;
    font-family: "Poppins"
  }

  .menuCardPricePartySingle {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    min-width: 60px;
    text-align: right;
    font-size: 14px;
    color: #282c30;
    font-weight: 500;
    font-family: "Poppins"
  }
}

.menuCardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuCardEntry {
  padding: 15px;
  margin: 10px 10px;
  flex-direction: row;
  min-height: 60px;
  border-color: #282c30;
  border-radius: 10px;
  border-width: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #e7e7e7;
  border-style: solid;
  display: flex;
  align-items: center;
}

.menuCardEntryColumn {
  text-align: start;
  display: flex;
  flex-direction: column;
  flex: 18 1;
}


.menuCardPriceColumn {
  text-align: start;
  display: flex;
  flex-direction: column;
  flex: 2 1;
}

.menuCardTitle {
  font-size: 18px;
  color: #282c30;
  font-weight: 600;
  font-family: "Poppins"
}

.menuCardSubtitle {
  color: #282c30;
  font-weight: 500;
  font-family: "Poppins"
}

.menuCardAllergenic {
  font-size: 13px;
  color: #282c30;
  font-weight: 400;
  font-family: "Poppins"
}
.to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  z-index: 950; /* add a higher z-index value to make sure it's on top */
}

.to-top-button:hover {
  background-color: #555;
}
.footer {
  left: 0;
  bottom: 0;
  width: 850px;
  background-color: #282c30;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 10px;
  z-index: 999;
}

.middle {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left {
  width: 30%;
  text-align: start;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {

  .FooterWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #282c30;
  }

  .SocialIconStyle {
    font-size: 25px;
    padding: 10px;
  }

  .right {
    padding: 3px;
    text-align: end;
    width: 30%;
  }

  .FooterMenuPoints{
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {

  .SocialIconStyle {
    font-size: 15px;
    padding: 5px;
  }

  .right {
    padding: 3px;
    text-align: end;
    font-size: 12px;
    width: 30%;
  }
  
  .FooterMenuPoints{
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) { 
    
  .ScrollableMenuWrapper{
    width: 800px;
  } 
}

@media (max-width: 768px) { 
    
  .ScrollableMenuWrapper{
    width: 100%;
  } 
}


.image-item {
  padding: 0px 10px;
}
.menuStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-multiple-carousel__arrow--left{
  left: 0px !important;
}
.react-multiple-carousel__arrow--right{
  right: 0px !important;
}
.react-multiple-carousel__arrow{
  min-width: 25px !important;
  min-height: 25px !important;
}
.react-multiple-carousel__arrow::before{
  font-size: 20px !important;
}
.react-multiple-carousel__arrow::after{
  font-size: 20px !important;
}
.imageStyle{
  width: 100%;
  height: 100px;
  object-fit: cover;

}

.App-header{
  overflow-x: scroll; 
  overflow-y:hidden;

}
.scrollable {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  padding: 0px;
  background-color: #282c30;
}

.scrollable::-webkit-scrollbar {
  display: none;
}


@media (min-width: 768px) {

  /* Only apply on screens that are at least 768px wide */
  .scrollable-container {
    position: relative;
    width: 100%;
  }

}


@media (max-width: 768px) {
  /* Only apply on screens that are at least 768px wide */

  .scroll-arrow {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    /*top: 70%;*/
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

}



@media (min-width: 768px) {
  .scroll-arrow {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    /*top: 50%;*/
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
}

.scroll-arrow:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.scroll-arrow-left {
  left: 0;
}

.scroll-arrow-right {
  right: 0;
}

.scroll-arrow i {
  color: #fff;
  font-size: 10px;
}
