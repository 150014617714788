@media (min-width: 768px) {

  .menuCardEntry {
    width: 700px;
  }

  .menuCardPrice {
    display: flex;
    flex: 2;
    justify-content: flex-end;
    min-width: 60px;
    text-align: right;
    font-size: 18px;
    color: #282c30;
    font-weight: 600;
    font-family: "Poppins"
  }

  .menuCardPricePartySingle {
    display: flex;
    flex: 2;
    justify-content: flex-end;
    min-width: 60px;
    text-align: right;
    font-size: 15px;
    color: #282c30;
    font-weight: 500;
    font-family: "Poppins"
  }
}

@media(max-width:767px) {
  .menuCardEntry {
    width: -webkit-fill-available;
  }

  .menuCardPrice {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    min-width: 70px;
    text-align: right;
    font-size: 18px;
    color: #282c30;
    font-weight: 600;
    font-family: "Poppins"
  }

  .menuCardPricePartySingle {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    min-width: 60px;
    text-align: right;
    font-size: 14px;
    color: #282c30;
    font-weight: 500;
    font-family: "Poppins"
  }
}

.menuCardWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuCardEntry {
  padding: 15px;
  margin: 10px 10px;
  flex-direction: row;
  min-height: 60px;
  border-color: #282c30;
  border-radius: 10px;
  border-width: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  background-color: #e7e7e7;
  border-style: solid;
  display: flex;
  align-items: center;
}

.menuCardEntryColumn {
  text-align: start;
  display: flex;
  flex-direction: column;
  flex: 18;
}


.menuCardPriceColumn {
  text-align: start;
  display: flex;
  flex-direction: column;
  flex: 2;
}

.menuCardTitle {
  font-size: 18px;
  color: #282c30;
  font-weight: 600;
  font-family: "Poppins"
}

.menuCardSubtitle {
  color: #282c30;
  font-weight: 500;
  font-family: "Poppins"
}

.menuCardAllergenic {
  font-size: 13px;
  color: #282c30;
  font-weight: 400;
  font-family: "Poppins"
}