.scrollable {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  padding: 0px;
  background-color: #282c30;
}

.scrollable::-webkit-scrollbar {
  display: none;
}


@media (min-width: 768px) {

  /* Only apply on screens that are at least 768px wide */
  .scrollable-container {
    position: relative;
    width: 100%;
  }

}


@media (max-width: 768px) {
  /* Only apply on screens that are at least 768px wide */

  .scroll-arrow {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    /*top: 70%;*/
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

}



@media (min-width: 768px) {
  .scroll-arrow {
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    /*top: 50%;*/
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
}

.scroll-arrow:hover {
  background-color: rgba(255, 255, 255, 0.7);
}

.scroll-arrow-left {
  left: 0;
}

.scroll-arrow-right {
  right: 0;
}

.scroll-arrow i {
  color: #fff;
  font-size: 10px;
}