.footer {
  left: 0;
  bottom: 0;
  width: 850px;
  background-color: #282c30;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px 10px;
  z-index: 999;
}

.middle {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left {
  width: 30%;
  text-align: start;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {

  .FooterWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #282c30;
  }

  .SocialIconStyle {
    font-size: 25px;
    padding: 10px;
  }

  .right {
    padding: 3px;
    text-align: end;
    width: 30%;
  }

  .FooterMenuPoints{
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 768px) {

  .SocialIconStyle {
    font-size: 15px;
    padding: 5px;
  }

  .right {
    padding: 3px;
    text-align: end;
    font-size: 12px;
    width: 30%;
  }
  
  .FooterMenuPoints{
    display: flex;
    flex-direction: column;
  }
}