.App {
    text-align: center;
  }
  
  .App-logo {
    height: 75px;
    padding: 10px;
    justify-content: center;
    display: flex;
  }

  .AppHeaderWrapper{
    width: 100%;
    background-color: #282c30;
    display: flex;
    justify-content: center;
  }
  
  .App-header {
    background-color: #282c30;
    height: 80px;
    width:850px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: white;  
  }


  .LogoWrapper{
    display: flex;
    justify-content: center;
  }

  .StickyStyle{
    position: sticky;
    top: 0;
    z-index: 500;
    width: 100%;
  }
  
  .App-header::-webkit-scrollbar {
    display: none;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  
  .MenuWrapper{
    background-color: #282c30;    
  }
  
  @media (min-width: 768px) { 
      
  .MenuTitle{
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  
  .MenuTitle img{
    height: 100%; 
    width: 730px;
    object-fit: contain;
  }

  .AppHeaderMiddle{
    display: flex;
    justify-content: center;
    width:30%;
    max-width:30%;
  }


  .AppHeaderLeft{
    display: flex;
    justify-content: start;
    width:35%;
    max-width:35%;
  }

  .AppHeaderRight{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #e7e7e7;
    align-items: flex-end;
    width:35%;
    max-width:35%;
  }

  
  .allergenicImageStyle{
    max-width: 800px;
  }


  }
  
  
  @media (max-width: 768px) { 
      
  .MenuTitle{
    width: 100%;
    overflow: hidden;
  }
  .MenuTitle img{
    height: 100%; 
    width: 100%; 
    object-fit: contain;
  }
  
  .AppHeaderLeft{
    display: flex;
    width:35%;
    max-width:35%;
  }

  
  .AppHeaderRight{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #e7e7e7;
    align-items: flex-end;
    width:35%;
    max-width:35%;
  }

  
  .allergenicImageStyle{
    max-width: 100%;
  }
  }
  
  .MenuTitleWrapper{
    display: block;
  }
  
  .App-footer {
    background-color: #282c30;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: white;
  }
  
  .LoadingWrapper{
    height:90vh
  }
