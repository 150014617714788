@media (min-width: 768px) { 
    
  .ScrollableMenuWrapper{
    width: 800px;
  } 
}

@media (max-width: 768px) { 
    
  .ScrollableMenuWrapper{
    width: 100%;
  } 
}


.image-item {
  padding: 0px 10px;
}
.menuStyle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-multiple-carousel__arrow--left{
  left: 0px !important;
}
.react-multiple-carousel__arrow--right{
  right: 0px !important;
}
.react-multiple-carousel__arrow{
  min-width: 25px !important;
  min-height: 25px !important;
}
.react-multiple-carousel__arrow::before{
  font-size: 20px !important;
}
.react-multiple-carousel__arrow::after{
  font-size: 20px !important;
}
.imageStyle{
  width: 100%;
  height: 100px;
  object-fit: cover;

}

.App-header{
  overflow-x: scroll; 
  overflow-y:hidden;

}