.App {
  text-align: center;
}

.App-logo {
  height: 75px;
  padding: 10px;
  justify-content: start;
  display: flex;
}

.App-header {
  background-color: #282c30;
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;

}

.App-header::-webkit-scrollbar {
  display: none;
}

.App-link {
  color: #61dafb;
}


.MenuWrapper{
  background-color: #282c30;

}

.App-footer {
  background-color: #282c30;
  min-height: 5vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
}

.FooterWrapper{
  display: flex;
  flex-direction: row;

}

.LoadingWrapper{
  height:90vh
}

