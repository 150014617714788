.to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: #333;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  z-index: 950; /* add a higher z-index value to make sure it's on top */
}

.to-top-button:hover {
  background-color: #555;
}